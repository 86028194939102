import React from 'react'
import { AdderTypes, dollars, estimatedClosingCosts, dollarsToNum, Validate, getNonDownPaymentAdders } from '@oneethos/shared'
import { IEquipmentCard } from './equipments'

type LoanSummaryProps = {
  formData: any
  selectedEquipment?: IEquipmentCard
}

export const LoanSummary = ({ formData, selectedEquipment }: LoanSummaryProps) => {

  const closingCosts = (
    formData.incentive?.loanFees
      ? parseFloat(formData.incentive.loanFees)
      : estimatedClosingCosts(
        dollarsToNum(formData.loanAmount),
        formData.installationAddress?.state,
        formData.projectType
      ))

  const amt = formData.incentive?.amountFinanced || formData.loanAmount
  const amountFinanced = dollarsToNum(amt) + closingCosts
  const equipmentType = formData.equipmentType || selectedEquipment?.id
  
  const adders = Validate.getValidAdders(formData.adders)
  const nonDownPaymentAdders = getNonDownPaymentAdders(adders, equipmentType)

  const downpaymentAdder = adders
    ?.find(adder => adder.description === AdderTypes.Downpayment)

  return (
    <>
      <table className="table table-borderless w-auto mb-1">
        <tbody>
          <tr>
            <td className="py-1">Solar Price:</td>
            <td className="text-end py-1">{dollars(formData.solarCost)}</td>
          </tr>
          {nonDownPaymentAdders?.map((adder, index) => (
            <tr key={index}>
              <td className="py-1">{adder.description}:</td>
              <td className="text-end py-1">
                {adder.amount}
              </td>
            </tr>
          ))}
          <tr className="border-top">
            <td className="py-1">Total System Cost:</td>
            <td className="text-end py-1">{dollars(formData.systemCost)}</td>
          </tr>
          <tr>
            <td className="py-1">Estimated Loan Fees*:</td>
            <td className="text-end py-1">{dollars(closingCosts)}</td>
          </tr>
          {
            downpaymentAdder && (
              <tr>
                <td className="py-1">Downpayment:</td>
                <td className="text-end py-1">- {dollars(downpaymentAdder?.amount)}</td>
              </tr>
            )
          }
          <tr className="border-top">
            <td className='fw-bold py-1'>Total Amount Financed:</td>
            <td className="text-end fw-bold py-1">{dollars(amountFinanced)}</td>
          </tr>
        </tbody>
      </table>
      <div className="text-small mb-4 fst-italic">
        *This is the estimate of the loan fees associated with processing your loan. This amount is
        rolled into the loan to be financed over the 30-year term.
      </div>
    </>
  )
}

export default LoanSummary
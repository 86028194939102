import { Spinner } from "@fluentui/react"
import { errorToString, Org } from "@oneethos/shared"
import { useState, useEffect } from "react"
import { toast } from "react-toastify"
import api from '../api-client'
import { useApi, useAppState } from "../hooks"
import { MultiSelect, PasswordInput, RoutingNumberInput, STATE_CODES, TextInput } from "./forms"

type OrgAdminProps = {
  installerID: string
}

export const OrgAdmin = ({ installerID }: OrgAdminProps) => {
  const [err, setErr] = useState('')
  const [org, setOrg] = useState<Org>(null)
  const [saving, setSaving] = useState(false)

  const { registration: { installer } } = useAppState()
  const { data, fetching, error } = useApi(`/orgs/${installerID}`)

  useEffect(() => {
    if (data) {
      if (!data.operatingStates?.length) {
        data.operatingStates = ['FL']
      }

      setOrg(data)
    }
  }, [data])

  if (fetching) {
    return <Spinner />
  }

  return <div className="org-admin">
    <div className="section-title">
      <h2 className="margin-bottom-0">
        Installer Org Settings
      </h2>
    </div>
    <div className="form-group">
      <label>Tesla Certified Installer</label>
      <div>
        <div className="form-check form-check-inline">
          <input
            type="radio"
            id="tesla-yes"
            name="isTeslaCertified"
            className="form-check-input"
            checked={org?.isTeslaCertified === true}
            onChange={() => setOrg({ ...org, isTeslaCertified: true })}
          />
          <label className="form-check-label" htmlFor="tesla-yes">Yes</label>
        </div>
        <div className="form-check form-check-inline">
          <input
            type="radio"
            id="tesla-no"
            name="isTeslaCertified"
            className="form-check-input"
            checked={org?.isTeslaCertified === false}
            onChange={() => setOrg({ ...org, isTeslaCertified: false })}
          />
          <label className="form-check-label" htmlFor="tesla-no">No</label>
        </div>
      </div>
    </div>
    <div className="form-group">
      <label>Operating States</label>
      <MultiSelect
        options={STATE_CODES}
        values={org?.operatingStates || []}
        onChange={states => setOrg({ ...org, operatingStates: states })}
        wrapStyle={{ maxHeight: '26em', overflowY: 'auto' }}
      />
    </div>
    {/* {installer.access === 'primary_admin'
        ? <PlaidAuthSelect 
                  onAuthSelect={ach => console.log(ach)}
          onManual={() => console.log('manual')}
          prompt="Add Payment Information"
         />
        : <div>Contact your primary administrator to verify payment information</div>} */}
    {installer.isAdmin || installer.isSupport ? <>
      <h4 className="mt-3">
        ACH Payment Information
      </h4>
      <div className="form-group">
        <label>Institution Name</label>
        <TextInput
          value={org?.achInstitutionName || ''}
          onChange={v => setOrg({ ...org, achInstitutionName: v })}
        />
      </div>
      <div className="form-group">
        <label>Routing Number</label>
        <RoutingNumberInput
          value={org?.achRoutingNo || ''}
          onChange={v => setOrg({ ...org, achRoutingNo: v })}
        />
      </div>
      <div className="form-group">
        <label>Account Number</label>
        <PasswordInput
          value={org?.achAccountNo || ''}
          onChange={v => setOrg({ ...org, achAccountNo: v })}
        />
      </div>
      <div className="form-group">
        <label>Account Type</label>
        <select value={org?.achAccountType}
          className="form-select"
          onChange={ev => setOrg({ ...org, achAccountType: ev.target.value })}>
          <option></option>
          <option value="checking">Checking</option>
          <option value="savings">Savings</option>
        </select>
      </div>
    </> : null}
    {error ? <div className="alert alert-danger">{error}</div> : null}
    <div className="buttons">
      <button className="btn btn-primary"
        disabled={saving}
        onClick={ev => {
          setSaving(true)
          api.patch(`/orgs/${installerID}`, org)
            .then(_org => { toast.success('Org Settings Updated'); setOrg(_org) })
            .catch(ex => { setErr(errorToString(ex)) })
            .finally(() => { setSaving(false) })
        }}
      >{saving ? <Spinner /> : 'Save Org Settings'}</button>
    </div>
  </div>
}

export default OrgAdmin
import { digit2, EMAIL_REGEX, isMasked, NAME_REGEX, PHONE_REGEX, DATE_REGEX, StreetAddress, dollarsToNum, AdderTypes } from './'
import { FormSchema } from './form-types'
import { validateDate, yearsAgo } from './date-utils'

/**
 * WARNING: these are misleading; they return truthy if there's an error
 */

export const isValidDate = (s: string): string => {
  if (!DATE_REGEX.test(s)) {
    return 'format must be mm/dd/yyyy'
  }

  const [m, d, y] = s.split('/')
  const date = new Date(`${y}-${digit2(m)}-${digit2(d)}T00:00:00Z`)
  if (date.toString() === 'Invalid Date') {
    return 'invalid date'
  }

  // return empty string if no error
  return ''
}

export const isValidEmail = (s: string): string => {
  return EMAIL_REGEX.test(s) ? '' : 'invalid email address'
}

export const isValidName = (s: string): string => {
  return NAME_REGEX.test(s) ? '' : 'invalid name, must be alphanumeric (,.-\' allowed)'
}

export const isValidPhone = (s: string): string => {
  return s?.match(PHONE_REGEX) ? '' : 'must be 10 digits, no punctuation'
}

export const isInteger = (s: string): string => {
  return s?.match(/^[0-9]+$/g) ? '' : 'must be only numbers, no punctuation'
}

export const isNumeric = (s: string): string => {
  return s?.match(/^[0-9.]+$/g) ? '' : 'must be valid number'
}

export const isRoutingNumber = (s: string): string => {
  return (
    s?.toString().match(/^[0-9]{9}$/g) ||
    isMasked(s)
  ) ? '' : 'must be only numbers, 9 digits long'
}

export const isValidSocial = (s: string): string => {
  return (
    s?.match(/^[0-9]{3}-[0-9]{2}-[0-9]{4}$/g) ||
    isMasked(s)
  ) ? '' : 'format must be NNN-NN-NNNN'
}

export const isValidDOB = (dob: string): string => {
  return validateDate(
    dob,
    (birthDate) => birthDate > yearsAgo(18) || birthDate < yearsAgo(100),
    'must be between 18 and 100 years old',
  )
}

export const isValidLicenseIssueDate = (issueDate: string): string => {
  return validateDate(
    issueDate,
    (issuedDate) => issuedDate > new Date(),
    'cannot be in the future',
  )
}

export const isValidLicenseExpirationDate = (expirationDate: string): string => {
  return validateDate(
    expirationDate,
    (expirationDate) => expirationDate < new Date(),
    'must be in the future',
  )
}

export const isValidZip = (s: string): string => {
  return s?.match(/^[0-9]{5}$/) ? '' : 'must be 5 digits'
}

export const isValidDollars = (s: string): string => {
  return s && s.match(/^\$[0-9,]+(\.[0-9]{2})?$/) ? '' : 'invalid dollar amount'
}


export const checkDollarValue = (s: string, extraValidationMessage?: string): string => {
  const isValid = isValidDollars(s)

  if (isValid) {
    return isValid
  }

  const numericValue = parseFloat(s.replace(/[$,]/g, ''))

  if (numericValue === 0) {
    return "value cannot be 0"
  }

  return extraValidationMessage || ''
}

export const ValidatorMap = {
  required: s => s ? '' : 'required',
  name: isValidName,
  ssn: isValidSocial,
  dob: isValidDOB,
  licenseIssue: isValidLicenseIssueDate,
  licenseExpiration: isValidLicenseExpirationDate,
  date: isValidDate,
  dollars: isValidDollars,
  dollarsNonZero: checkDollarValue,
  email: isValidEmail,
  address: s => StreetAddress.isValid(s) ? '' : 'invalid street address',
  phone: isValidPhone,
  zip: isValidZip,
  numeric: isNumeric,
  integer: isInteger,
  routing: isRoutingNumber
}

export const validateFields = (
  schema: FormSchema,
  data: Record<string, unknown>,
  fields: string[]
): Record<string, string> => {
  const errors = {}
  for (const field of fields) {
    const config = schema[field]
    if (typeof config == 'string') {
      if (data[field] === undefined || data[field] === '') {
        errors[field] = `${config} is required`
      }
    } else if (config?.type) {
      const validator = ValidatorMap[config.type]
      if (!validator) {
        console.error('no validator for', config.type, data[field])
        continue
      }
      const error = validator(data[field] || '')
      // const error = validator(data[field]?.toString() || '')
      if (error) {
        errors[field] = `${config.label || field} ${error}`
      }
    } else {
      const label = config?.label || field
      if (!config) {
        console.warn('no validation config for field', field)
      } else {
        console.log(`no specific config for ${field}, treating as required string`)
      }

      if (data[field] === undefined || data[field] === '') {
        errors[field] = `${label} is required`
      }
    }
  }
  return errors
}

export const getValidAdders = (adders) => {
  if (!adders) return []
  return adders.filter(adder => {
    const amount = dollarsToNum(adder.amount)
    return amount > 0
  })
}

export const normalizeAdders = (adders) => {
  if (!adders) return []
  return adders.map(adder => ({
    description: adder.description,
    amount: dollarsToNum(adder.amount)
  }))
}

export const escapeRegex = (patt: string): string => {
  // Replace special regex characters with their escaped versions
  // $& means the whole matched substring
  return patt.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') 
}


// This function constructs a pattern that allows for any characters
// to appear between the characters of the input string.
export const createRegexPattern = (input : string): string => {
  let pattern = ''
  for (let i = 0; i < input.length; i++) {
    if (i > 0) {
      pattern += '.*'
    }
    pattern += input[i]
    if (input[i] === '\\' && i + 1 < input.length) {
      pattern += input[i + 1]
      i++
    }
  }
  return pattern
}